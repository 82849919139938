import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './style.css';
import UserDataContext from "../../context/UserData";

const Login = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    school: ""
  });

  const handleChange = (event) => {
    const value = event.target.value;
    
    setData({
      ...data,
      [event.target.name]: value
    });
  };

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      nickname: data.nickname,
      school: data.school
    };

    localStorage.setItem('firstName', data.firstName);
    localStorage.setItem('lastName', data.lastName);
    localStorage.setItem('email', data.email);
    localStorage.setItem('school', data.school);

    navigate("home");
  };

  return (
    <UserDataContext.Provider value={{userData: setData}}>
      <div className="login-page">
        <div className="form">
          <form className="login-form" onSubmit={handleSubmit}>
            <input type="text" placeholder="First Name" name="firstName" value={data.firstName} onChange={handleChange} />
            <input type="text" placeholder="Last Name" name="lastName" value={data.lastName} onChange={handleChange} />
            <input type="email" placeholder="E-mail" name="email" value={data.email} onChange={handleChange} />
            <select name="school" value={data.school} onChange={handleChange}>
              <option value="">Select School</option>
              <option value="Blaine">Blaine</option>
              <option value="Greenberg">Greenberg</option>
              <option value="Mifflin">Mifflin</option>
              <option value="Olney">Olney</option>
              <option value="Rhodes">Rhodes</option>
              <option value="Stanton">Stanton</option>
              <option value="Steele">Steele</option>
            </select>
            <button>Send</button>
          </form>
        </div>
      </div>
    </UserDataContext.Provider>
  );
}
  
export default Login;