import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import Swal from 'sweetalert2';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './style.css';

const Home = () => {
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const school = localStorage.getItem('school');

  const fullName = firstName + lastName;
  const folder = 'StudentWebsites/' + school + '/' + firstName.trim() + lastName.trim();

  const [file, setFile] = useState(null);
  const [userFiles, setUserFiles] = useState([]);
  const [updateFileList, setUpdateFileList] = useState('');
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(true);
  
  const [aliases, setAliases] = useState(Array(userFiles.length).fill(''));
  
  const S3_BUCKET = 'steamworksstudio';
  const REGION = 'us-east-2';

  useEffect(() => {
    getUserFiles();
  }, [updateFileList]);

  const handleFileChange = (e) => {
    const userFile = e.target.files[0];
    setFile(userFile);
    setUploadButtonDisabled(false);
  };

  const handleChange = (event, index) => {
    setPublishButtonDisabled(false);

    if (!event.target.value.includes(' ')) {
      setAliases((prevAliases) => {
        const updatedAliases = [...prevAliases];
        updatedAliases[index] = event.target.value;
        return updatedAliases;
      });
    }
  };

  const getUserFiles = async () => {
    AWS.config.update({
      accessKeyId: 'AKIA4LEJ6JWATDMJSAWL',
      secretAccessKey: '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',
      //accessKeyId: process.env.ACCESS_KEY,
      //secretAccessKey: process.env.SECRET_KEY,
    });
    
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Delimiter: '',
      Prefix: folder
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
        
      const files = await Promise.all(data.Contents.map(async (object) => {
        const objectParams = {
          Bucket: S3_BUCKET,
          Key: object.Key
        };
  
        try {
          const tags = await s3.getObjectTagging(objectParams).promise();
  
          return {
            Key: object.Key,
            Size: object.Size,
            LastModified: object.LastModified,
            Url: (tags.TagSet.length > 0) ? tags.TagSet[0].Value : ''
          };
        } catch (error) {
          console.error(`Error getting tags for object ${object.Key}:`, error);
          return null;
        }
      }));
  
      const validFiles = files.filter(file => file !== null);
  
      setUserFiles(validFiles);
    } catch (error) {
      toast.error(`Error fetching file: ${error}`, {
        position: 'top-right',
      });
    }
  }

  const uploadFile = async () => {
    AWS.config.update({
      accessKeyId: 'AKIA4LEJ6JWATDMJSAWL',
      secretAccessKey: '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',
      //accessKeyId: process.env.ACCESS_KEY,
      //secretAccessKey: process.env.SECRET_KEY,
    });
    
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const fileName = file.name.replace(' ', '-')
      .replace('^', '-')
      .replace('!', '-')
      .replace('@', '-')
      .replace('#', '-')
      .replace('$', '-')
      .replace('%', '-')
      .replace('*', '-')
      .replace('+', '-')
      .replace('^', '-')
      .replace('[', '-')
      .replace(']', '-')
      .replace('(', '-')
      .replace(')', '-')
      .replace('{', '-')
      .replace('}', '-')
      .replace('|', '-')
      .replace('/', '-')
      .replace(':', '-')
      .replace('`', '-')
      .replace('"', '-')
      .replace('\'',  '-')
      .replace('\\', '-');

    const params = {
      Bucket: S3_BUCKET,
      Key: folder + '/' + fileName,
      Body: file,
      ContentType: file.type,
      ContentDisposition: 'inline'
    };

    try {
      const upload = await s3.putObject(params).promise();
      setUpdateFileList(upload);
      document.getElementById('file').value = null;
      setUploadButtonDisabled(true);
    } catch (error) {
      toast.error(`Error uploading file: ${error.message}`, {
        position: 'top-right',
      });
    }
  };

  const deleteFile = async (file) => {
    AWS.config.update({
      accessKeyId: 'AKIA4LEJ6JWATDMJSAWL',
      secretAccessKey: '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',
      //accessKeyId: process.env.ACCESS_KEY,
      //secretAccessKey: process.env.SECRET_KEY,
    });
    
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: file
    };

    Swal.fire({
      title: "Are you sure you want to delete this file?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        s3.deleteObject(params, function (error, data) {
          if (data) {
            setUpdateFileList(data);

            toast.error('File deleted successfully!', {
              position: 'top-right',
            });
          } else {
            toast.error('Failed to delete file. Check if you have sufficient permissions.', {
              position: 'top-right',
            });
          }
        });
      }
    });
  };

  const createRedirectRule = async (key, index) => {
    const fileName = key.slice(key.lastIndexOf('/') + 1);    

    const alias = aliases[index];

    const body = {
      alias: alias,
      rewriteCond: `RewriteCond %{HTTP_HOST} ^web.steamworksstudio.com$ [NC]`,
      rewriteRule: `RewriteRule ^${alias}$ https://steamworksstudio.s3.us-east-2.amazonaws.com/StudentWebsites/${school}/${fullName}/${fileName} [L,R=301]`
    }

    axios.post('http://3.128.164.83:3031/create-redirect-rule', body).then((response) => {
      if (response.data.isAliasAlreadyRegistered) {
        toast.error('This name is already registered. Please choose another name!', {
          position: 'top-right',
        });
        
        return;
      }

      setPublishButtonDisabled(true);

      const url = `http://web.steamworksstudio.com/${alias}`;

      const tag = {
        Key: 'url',
        Value: url
      };
  
      setObjectTag(key, tag)

      const index = userFiles.findIndex(file => file.Key === key);

      if (index !== -1) {
        const updatedUserFiles = [...userFiles];
        updatedUserFiles[index].Url = url;
        setUserFiles(updatedUserFiles);
      }
    }).catch((error) => {
      toast.error(`Error publishing site: ${error}`, {
        position: 'top-right',
      });
      
      return Promise.reject(error)
    });
  }

  const setObjectTag = async (key, tag) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Tagging: {
        TagSet: [tag]
      }
    };

    const s3 = new AWS.S3({
      params: params,
      region: REGION,
    });

    s3.putObjectTagging(params, (err, data) => {
      if (err) {
        toast.error(`Error publishing site!`, {
          position: 'top-right',
        });
      } else {
        toast.success(`Site published successfully on: ${tag.Value}`, {
          position: 'top-right',
        });
      }
    });
  }

  const unpublishSite = (file) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: file.Key
    };

    const s3 = new AWS.S3({
      params: params,
      region: REGION,
    });
    
    Swal.fire({
      title: "Are you sure you want to unpublish this file?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        s3.deleteObjectTagging(params, (error, data) => {
          if (error) {
            toast.error(`Error unpublishing site: ${error}`, {
              position: 'top-right',
            });
            
            return;
          }
        });
    
        const alias = file.Url.slice(file.Url.lastIndexOf('/') + 1);
    
        const body = {
          alias: alias
        }
    
        axios.post('http://3.128.164.83:3031/remove-redirect-rule', body).then(() => {
          setPublishButtonDisabled(false);

          const index = userFiles.findIndex(object => object.Key === file.Key);

          if (index !== -1) {
            const updatedUserFiles = [...userFiles];
            updatedUserFiles[index].Url = '';
            setUserFiles(updatedUserFiles);
          }

          setAliases((prevAliases) => {
            const updatedAliases = [...prevAliases];
            updatedAliases[index] = '';
            return updatedAliases;
          });
        }).catch((error) => {
            toast.error(`Error unpublishing site: ${error}`, {
              position: 'top-right',
            });

            return Promise.reject(error)
        });
      }
    });
  }

  return(
    <div className="home-page">
      <ToastContainer />
      <h3>{firstName} {lastName} {' - ' + school + ' School'}</h3>
      <table className='table'>
        <thead>
          <tr>
            <th>File</th>
            <th>Site URL</th>
            <th>Size</th>
            <th>Last Modified</th>
            <th>Delete</th>
            <th>Publish</th>
          </tr>
        </thead>
        <tbody>
        {userFiles && userFiles.map((file, index) => {
          const fileName = file.Key.slice(file.Key.lastIndexOf('/') + 1);
          const extension = file.Key.slice(file.Key.lastIndexOf('.') + 1);

          if (file.Size > 0) {
            return (
              <tr key={index}>
                <td><a target="_blank" href={`https://steamworksstudio.s3.us-east-2.amazonaws.com/${file.Key}`}>{fileName}</a></td>
                <td><a target="_blank" href={file.Url}>{file.Url}</a></td>
                <td>{file.Size + ' bytes'}</td>
                <td>{new Date(file.LastModified + '').toLocaleString()}</td>
                <td><button className="btn-danger" onClick={() => deleteFile(file.Key)}>Delete</button></td>
                {extension == 'html' || extension == 'htm' ? file.Url != '' ? (
                  <td>
                    <button className="btn-danger inline" onClick={() => unpublishSite(file)}>Unpublish</button>
                  </td>
                ) : (
                  <td>
                    <input className="inline" type="text" placeholder="Choose a name ..." name={`alias${index}`} value={aliases[index]} onChange={(event) => handleChange(event, index)} />
                    <button className="btn-publish inline" onClick={() => createRedirectRule(file.Key, index)} disabled={publishButtonDisabled}>Publish</button>
                  </td>
                ) : (
                  <td></td>
                )}
              </tr>
            )
          }
        })}
        </tbody>
      </table> 
      <br /><br />
      <div className="form">
        <label>Upload your site</label><br /><br />
        <input type="file" id="file" onChange={handleFileChange} />
        <button className="btn-publish" onClick={uploadFile} id="upload-button" disabled={uploadButtonDisabled}>Upload</button>
      </div>
    </div>
  );
}

export default Home;